import './App.css';
import { sha256 } from 'js-sha256';
import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom"

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    naranja: {
      main: '#F36E31',
      contrastText: '#fff',
    },
    negro: {
      main: '#242424',
      contrastText: '#fff',
    },
    blanco: {
      main: "#FFFFFF"
    }
  },
});

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

function Home() {
  const [searchParams] = useSearchParams();

  const importePorDefecto = "100";
  const conceptoPorDefecto = ""

  const [otraCantidad, setOtraCantidad] = useState(false);
  const [ImporteDeseado, setImporteDeseado] = useState("0"); // el valor que introduce el usuario
  const [Importe, setImporte] = useState("0"); // valor final con el formato adecuado para el formulario
  const [ImporteValidado, setImporteValidado] = useState("0"); // valor que ya ha sido validado como correcto despues de introducirlo el usuario
  const [error, setError] = useState(false);
  const [pagoOK, setPagoOK] = useState(null);
  const [pagoKO, setPagoKO] = useState(null);
  const [Num_operacion, setNum_operacion] = useState("0");
  const [concepto, setConcepto] = useState(conceptoPorDefecto);
  const [errorConcepto, setErrorConcepto] = useState(false);
  const [firma, setFirma] = useState("");

  const MerchantID = "086642808";
  const AcquirerBIN = "0000554027";
  const TerminalID = "00000003";
  const URL_OK = "https://xn--cdalfozdequintanadueas-3ec.es?Pago=OK";
  const URL_NOK = "https://xn--cdalfozdequintanadueas-3ec.es?Pago=KO";

  const Cifrado = "SHA2";
  const TipoMoneda = "978";
  const Exponente = "2";
  const Pago_soportado = "SSL";
  const Idioma = 1;

  const clave_encriptacion = process.env.REACT_APP_CLAVE_ENCRIPTACION;
  const API_URL = process.env.REACT_APP_API_URL;

  const Cadena_sha2 = clave_encriptacion + MerchantID + AcquirerBIN + TerminalID + Num_operacion +
    Importe + TipoMoneda + Exponente + Cifrado + URL_OK + URL_NOK

  useEffect(() => {
    setFirma(sha256(Cadena_sha2))
  }, [Importe, Num_operacion])

  useEffect(() => {
    handleInputChange(importePorDefecto)
    handleSetNum_operacion(conceptoPorDefecto)
  }, [])

  const handleInputChange = (value) => {

    setImporteDeseado(value)

    const re = /^(?!0)[0-9]+([.,][0-9]{1,2})?$/;

    if (re.test(value)) {
      setImporteValidado(value.replace(",", "."));
      setImporte(parseFloat(value.replace(",", ".")).toFixed(2).replace(".", ""));
      setError(false)
    } else {
      setImporteValidado(0)
      setImporte(0)
      setError(true)
    }
  }

  const handleSetNum_operacion = (value) => {
    setConcepto(value)

    //regex para comprobar que el concepto no tiene caracteres especiales
    const re = /^[a-zA-Z0-9\s]*$/;

    if (re.test(value) && value.length <= 40) {
      setNum_operacion(value + " " + Math.floor(Math.random() * (99999 - 10000 + 1) + 10000))
      setErrorConcepto(false)
    } else {
      setErrorConcepto(true)
    }
  }

  useEffect(() => {
    if (searchParams.get("Pago") === "OK") {
      setPagoOK(true)
      setPagoKO(false)
    } else if (searchParams.get("Pago") === "KO") {
      setPagoOK(false)
      setPagoKO(true)
    }
  }, [searchParams])


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <form action={API_URL} method="POST"
          encType="application/x-www-form-urlencoded">
          <input name="MerchantID" type="hidden" value={MerchantID} />
          <input name="AcquirerBIN" type="hidden" value={AcquirerBIN} />
          <input name="TerminalID" type="hidden" value={TerminalID} />
          <input name="URL_OK" type="hidden" value={URL_OK} />
          <input name="URL_NOK" type="hidden" value={URL_NOK} />
          <input name="Num_operacion" type="hidden" value={Num_operacion} />
          <input name="Firma" type="hidden" value={firma} />
          <input name="Cifrado" type="hidden" value={Cifrado} />
          <input name="Importe" type="hidden" value={Importe} />
          <input name="TipoMoneda" type="hidden" value={TipoMoneda} />
          <input name="Exponente" type="hidden" value={Exponente} />
          <input name="Pago_soportado" type="hidden" value={Pago_soportado} />
          <input name="Pago_elegido" type="hidden" value={""} />
          <input name="Idioma" type="hidden" value={Idioma} />
          <Typography variant="h4" color="blanco.main" sx={{ my: 1.5, py:1.5, bgcolor: 'negro.main'}}>
            CD Quintanadueñas
          </Typography>
          <Divider />
          <Typography variant="h5"sx={{ my: 1}}>
            Hacer un pago
          </Typography>
          {(
            <>
              <div style={{ "margin": "15px" }}>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("100")}>100</Button>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("150")}>150</Button>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("170")}>170</Button>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("190")}>190</Button>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("200")}>200</Button>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => handleInputChange("250")}>250</Button>
              </div>
              <div style={{ "margin": "15px" }}>
                <Button size="small" color="negro" variant="outlined" value="Comprar" onClick={() => setOtraCantidad(true)} >Otra cantidad</Button>
              </div>
              {otraCantidad &&
                <TextField
                  id="outlined-required"
                  error={error}
                  helperText={error && "La cantidad introducida no es válida"}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                  label="Introduce una cantidad"
                  value={ImporteDeseado}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              }
              <div style={{ "margin": "15px" }}>
                <TextField
                  error={errorConcepto}
                  id="outlined-error"
                  label="Introduce un concepto"
                  helperText={errorConcepto &&
                    <>
                      Concepto no válido.
                      <br />
                      Solo se permiten 40 caracteres sin símbolos (¿,?,%,&,*, etc)
                    </>}
                  value={concepto}
                  onChange={(e) => handleSetNum_operacion(e.target.value)}
                  style={{ width: "100%", maxWidth: 400 }}
                />
              </div>
              <div style={{ "margin": "15px" }}>
                {ImporteValidado > 0 && !errorConcepto && (<Button color="naranja" variant="contained" type="submit" value="Comprar" style={{ "white-space": "-moz-pre-wrap" }}> Pagar {ImporteValidado} €</Button>)}
              </div>
            </>
          )}
          <div>
            <Box
              display="flex"
              width={"100%"}
              height={80}
              alignItems="center"
              justifyContent="center"
            >
              {pagoOK && <Alert onClose={() => setPagoOK(false)} severity="success">El pago se ha realizado con éxito. ¡Gracias!</Alert>}
              {pagoKO && <Alert onClose={() => setPagoKO(false)} severity="error">El pago no se ha completado correctamente</Alert>}
            </Box>
          </div>
        </form>
      </div >
    </ThemeProvider>
  );
};